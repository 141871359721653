.featured-card {
  width: 30%;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: inline-block;
}

@media (max-width: 600px) {
  .featured-card {
    width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .featured-card {
    width: 40%;
  }
}

/* Event Card Image */
.featured-card-img img {
  width: 100%;
  object-fit: cover;
}

/* Event Card Content */
.featured-card-content {
  padding: 15px;
}

.featured-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2em;
}

.featured-card-desc {
  margin: 0.5rem 0;
  color: #333;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  max-width: 100%;
  line-height: 1.2em;
  width: 100%;
  min-height: 2.4em; /* Minimum height equivalent to 2 lines of text */
}

/* Event Card Actions */
.featured-card-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background: #f4f4f4;
}

.featured-card-actions button {
  border: none;
  background: #333;
  color: #fff;
  padding: 8px 16px; /* Adjust padding as needed */
  cursor: pointer;
}

.featured-card-span-desc {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit text to 2 lines */
  -webkit-box-orient: vertical;

  /* word-wrap: break-word; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  max-width: 100%;
  max-height: 2.4em;
  line-height: 1.2em;
  width: 100%;
  height: 2.4em;
  min-height: 2.4em; /* Add this line */
}

.no-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px; /* Adjust the height to match your card height */
  border: 1px solid #ccc; /* Add a border around the box */
  background-color: #f4f4f4; /* Add a background color */
  color: #333;
  font-size: 16px; /* Adjust the font size as needed */
}
