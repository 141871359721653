.mosque-card {
  margin: 10px;
  max-width: 500px;
}

.mosque-table {
  margin-bottom: 20px;
}

.mosque-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.mosque-subtitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.mosque-text {
  font-size: 14px;
  margin-bottom: 5px;
}
