.event-card {
  width: 60%; /* Adjust the width as needed */
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.event-card-img img {
  width: 100%;
  object-fit: fill;
  height: 250px; /* Adjust the height to match your card height */
}

/* Event Card Content */
.event-card-content {
  padding: 15px;
}

/* Event Card Title */
.event-card-title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Event Card Description */
/* .event-card-desc {
  margin: 0.5rem 0;
  color: #333;
  font-size: 0.9rem;
} */

.event-card-desc {
  margin: 0.5rem 0;
  color: #333;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-align: left;
}

/* Event Card Actions */
.event-card-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background: #f4f4f4;
}

.event-card-actions button {
  border: none;
  background: #4a6741;
  color: #fff;
  padding: 8px 16px; /* Adjust padding as needed */
  cursor: pointer;
}

/* .event-card-span-desc {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  max-height: 2.4em;
  line-height: 1.2em;
  width: 100%;
  height: 2.4em;
} */

.no-image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px; /* Adjust the height to match your card height */
  border: 1px solid #ccc; /* Add a border around the box */
  background-color: #f4f4f4; /* Add a background color */
  color: #333;
  font-size: 16px; /* Adjust the font size as needed */
}

.events-filter {
  display: flex;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 10px;
  justify-content: center;
}

.event-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
