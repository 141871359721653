.body {
  font-family: sans-serif;
}

@media (min-width: 768px) {
  /* Adjust the breakpoint as needed */
  .temp-RamadanCalendar img {
    max-height: 600px; /* Set a taller maximum height for larger screens */
  }
}
