.page-title {
  color: #ffff;
  /* background: linear-gradient(to left, #4a6741, #4a6d41); */
  background-color: #4a6741;
  padding: 1rem 0;
  padding-top: 6rem;
  box-sizing: border-box;
  width: 100%;
}

@media (min-width: 600px) and (max-width: 999px) {
  .page-title {
    border-left: 10rem solid transparent;
    border-right: 10rem solid transparent;
    border-bottom: 2rem solid #f9e4bc;
  }
}

@media (min-width: 1000px) {
  .page-title {
    border-left: 25rem solid transparent;
    border-right: 25rem solid transparent;
    border-bottom: 3rem solid #f9e4bc;
  }
}

.secondry-hero-text {
  color: #e7e7e7;
}
